<template>
  <div class="login-page">
    <div class="left-wrap">
      <img src="../assets/img/logo_icon.png">
    </div>
    <div class="login-wrap">
      <div class="common-wrap first-wrap">
        <div class="title">绑定手机号</div>
        <div class="content">
          <div class="phone-wrap">
            <div class="input-wrap">
              <img src="../assets/img/phone_icon.png" />
              <input v-model="dataForm.mobile" placeholder="请输入手机号码" />
            </div>
            <div class="code-wrap">
              <div class="input-wrap code-input">
                <img src="../assets/img/code_icon.png" />
                <input v-model="dataForm.captcha" placeholder="请输入验证码" @keyup.enter.prevent="bindMobile()" />
              </div>
              <div v-if="!isGetCode" class="code-btn" @click="getCode()">获取验证码</div>
              <div v-if="isGetCode" class="code-btn code-btn-disabled">{{ codeCount }}s</div>
            </div>
            <div class="login-btn" @click.prevent="bindMobile()">立即绑定</div>
          </div>
        </div>
        <div class="first-notice">应《中华人民共和国网络安全法》要求，使用互联网服务需进行帐号实名认证，为保障你的帐号能够正常使用，需绑定手机号码</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils';
import { Message } from 'element-ui';
import * as loginService from '@/service/login-service';

export default {
  name: 'bindMobile',
  data() {
    return {
      dataForm: {
        mobile: '',
        captcha: '',
        third_id: ''
      },
      isGetCode: false,
      codeCount: 60
    };
  },
  mounted() {
    this.dataForm.third_id = this.$route.query.thirdId;
  },
  methods: {
    /**
     * 获取验证码
     * */
    getCode() {
      if (Utils.isVariableEmpty(this.dataForm.mobile)) {
        Message({
          message: '请输入手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (!Utils.isMobile(this.dataForm.mobile)) {
        Message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      const data = {
        'mobile': this.dataForm.mobile,
        'event': 'bind'
      };
      loginService.getSmsSendCode(data).then((res) => {
        if (res.code === 1) {
          if (!this.isGetCode) {
            this.codeCount = 60;
            this.isGetCode = true;
            const codeVal = setInterval(() => {
              if (this.codeCount === 0) {
                this.isGetCode = false;
                this.codeCount = 60;
                clearInterval(codeVal);
              }
              else {
                --this.codeCount;
              }
            }, 1000);
          }
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }).catch((error) => {
        Message({
          message: error.msg,
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      });
    },
    bindMobile() {
      if (Utils.isVariableEmpty(this.dataForm.mobile)) {
        Message({
          message: '请输入手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (!Utils.isMobile(this.dataForm.mobile)) {
        Message({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      if (Utils.isVariableEmpty(this.dataForm.captcha)) {
        Message({
          message: '请输入验证码',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      loginService.bindThirdMobile(this.dataForm).then(res => {
        if (res.code === 1) {
          Utils.setLocalStorage('LoginState', true);
          Utils.setLocalStorage('LoginUser', res.data.userinfo);
          const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
          this.$router.replace(path);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url("../assets/img/login_bg.png") no-repeat center top #3D76F8;
  background-size: cover;
  .left-wrap {
    margin-right: 120px;
    img {
      width: 630px;
      height: 391px;
    }
  }
  .login-wrap {
    width: 420px;
    height: 460px;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 72px 0 90px;
    .common-wrap {
      display: flex;
      flex-direction: column;
      padding: 45px 40px 30px 40px;
      height: 100%;
      .title {
        display: flex;
        justify-content: space-between;
        margin-left: 2px;
        span {
          font-size: 22px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
        .title-selected {
          color: #222222;
        }
      }
      .content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .input-wrap {
          display: flex;
          align-items: center;
          padding: 0 14px;
          width: 340px;
          height: 50px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          margin-bottom: 30px;
          img {
            width: 19px;
            height: 26px;
            margin-right: 14px;
          }
          input {
            flex: 1;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
          input::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: #999999;
          }
        }
        .code-wrap {
          display: flex;
          justify-content: space-between;
          .code-input {
            width: 200px;
            img {
              width: 21px;
              height: 24px;
            }
            input {
              width: 139px;
            }
          }
          .code-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 50px;
            background: #2F6DF6;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
          }
          .code-btn-disabled {
            background: #77777D;
            cursor: none;
          }
        }
        .login-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          width: 340px;
          height: 50px;
          background: #2F6DF6;
          border-radius: 4px;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .code-blank {
          display: flex;
          flex-direction: column;
          .code-info {
            width: 200px;
            height: 200px;
            background: #FFFFFF;
            border: 1px solid #F8F8F8;
            margin-bottom: 29px;
          }
          span {
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
        }
      }
      .other-wrap {
        margin: 0 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 16px;
          font-weight: 400;
          color: #999999;
        }
        img {
          width: 40px;
          height: 40px;
          margin-left: 35px;
          cursor: pointer;
        }
      }
    }
  }
  .first-wrap {
    padding: 45px 35px 43px !important;
    .title {
      margin-left: 7px;
      font-size: 22px;
      color: #222222;
    }
    .first-notice {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      text-align: center;
    }
  }
}
</style>
